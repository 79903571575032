import personSettings from '@/settings/persons.js'
import branchSettings from '@/settings/branches.js'
import organizationSettings from '@/settings/organizations.js'
import registrationSettings from '@/settings/registrations.js'

const resourceSettings = {
    "persons": personSettings,
    "branches": branchSettings,
    "organizations": organizationSettings,
    "registrations": registrationSettings
}

export function getSettings(resources){
   let settings = []

   for (let i = 0; i < resources.length; i++) {
       const resource = resources[i]
       settings = settings.concat(resourceSettings[resource])
   }

   // settings = settings.map(setting => {
   //     setting.source = templateSettings[setting.field]?.source || ""
   //     return setting
   // })

   return settings
}

// export personSettings
// export branchSettings
// export registrationSettings
