<template>
    <div class="page">

        <h1><router-link :to="`/${recipientType}`"><i v-html="$icon('chevron-left')"></i>{{pageTitle}}</router-link> / {{recipient.tradeName || recipient.lastName}}</h1>

        <section class="white">
            <button xclick="showPopup = 'select-file'" disabled><i v-html="$icon('plus')"></i>Registratie toevoegen</button>
        </section>

        <section class="white overflow" v-if="!loading">
            <h2>Registraties</h2>
            <rtable :columns="columns" :data="data"></rtable>
        </section>

        <div class="loader" v-if="loading"><i v-html="$icon('arrow-path')"></i></div>

    </div>

</template>

<script>

import rtable from '@/components/rtable.vue'

// import personSettings from '@/settings/persons.js'
// import branchSettings from '@/settings/branches.js'
// import organizationSettings from '@/settings/organizations.js'
import registrationSettings from '@/settings/registrations.js'

import popupSelectFile from '@/components/popup-select-file.vue'
import popupParseFile from '@/components/popup-parse-file.vue'

export default {
    name: 'upload',
    components: {
        rtable,
        popupSelectFile,
        popupParseFile
    },
    computed: {
        headerAuth() {
            return this.$store.getters.headerAuth
        },
        columns() {
            return [
                {
                    "field": "definitionTitle",
                    "title": "Definitie",
                },
                {
                    "field": "definitionType",
                    "title": "Type",
                },
                {
                    "field": "startDate",
                    "title": "Startdatum",
                },
                {
                    "field": "endDate",
                    "title": "Einddatum",
                },
                {
                    "field": "evaluationDate",
                    "title": "Evaluatiedatum",
                },
                {
                    "field": "number",
                    "title": "Nummer",
                },
                {
                    "field": "reference",
                    "title": "Referentie",
                },
                {
                    "field": "status",
                    "title": "Status",
                    "type": "labels"
                }
            ]
        },
        activeMe() {
            return this.$store.getters.activeMe || ""
        },
        recipientType(){
            return this.$route.meta.recipientType
        },
        pageTitle(){
            return this.$route.meta.title
        },
        routeId(){
            return this.$route.params.id
        }
    },
    watch: {
        routeId(){
            if(this.routeId){
                this.getRecipient()
            }
        }
    },
    data() {
        return {
            loading: true,
            showPopup: "",
            popupSelectFileData: null,
            error: "",
            data: [],
            recipient: {}
        }
    },
    mounted(){
        this.getRecipient()
        this.getRegistrations()
    },
    methods: {
        async getRegistrations() {
            try {
                this.loading = true
                const response = await this.$http.get(`/recipients/${this.recipientType}/${this.routeId}/registrations`, this.headerAuth)
                const data = response?.data?.registrations || []
                this.data = data.map(row => {
                    row.definitionType = row.definition.type
                    row.definitionTitle = row.definition.title
                    let color = row.status == "active" ? "green" : "red"
                    row.status = [{color, value: row.status}]
                    return row
                })
                this.loading = false
            } catch (error) {
                console.error(error)
                this.error = error?.response?.data?.description || error.toString()
            }
        },
        async getRecipient() {
            try {
                this.loading = true
                const response = await this.$http.get(`/recipients/${this.recipientType}/${this.routeId}`, this.headerAuth)
                this.recipient = response?.data || {}
            } catch (error) {
                console.error(error)
                this.error = error?.response?.data?.description || error.toString()
            }
        }
    }
}
</script>

<style lang="scss">
.loader {
    display: flex;

    i {
        width: 40px;
        height: 40px;
    }
}
</style>