export default [
    {
        "field": "referenceRecipient",
        "title": "Referentie Persoon",
        "description": "Reference Person",
        "pattern": /^[\u0020-\u007E]{1,100}$/,
        "type": "string",
        "required": false,
    },
    {
        "field": "initials",
        "title": "Initialen",
        "description": "The initials of the person",
        "pattern": /^[a-zA-Z]{1,6}$/,
        "type": "string",
        "required": true,
    },
    {
        "field": "firstName",
        "title": "Voornaam",
        "description": "The first name of the person",
        "pattern": /^[a-zA-Z\u00C0-\u00FF .'-]{2,50}$/,
        "type": "string",
        "required": false,
    },
    {
        "field": "lastName",
        "title": "Achternaam",
        "description": "The last name of the person",
        "pattern": /^[a-zA-Z\u00C0-\u00FF .'-|]{2,50}$/,
        "type": "string",
        "required": true,
    },
    {
        "field": "postalCode",
        "title": "Postcode",
        "description": "The postal code of the person",
        "pattern": /^[0-9]{4}[A-Z]{2}$|^[0-9]{4,5}$/,
        "type": "string",
        "required": false,
    },
    {
        "field": "houseNumber",
        "title": "Huisnummer",
        "description": "The house number of the person",
        "pattern": /^[0-9]{1,5}$/,
        "type": "string",
        "required": false,
    },
    {
        "field": "houseNumberPostfix",
        "title": "Toevoeging",
        "description": "The house number postfix of the person",
        "pattern": /^[a-zA-Z .'-]{1,20}$/,
        "type": "string",
        "required": false,
    },
    {
        "field": "countryCode",
        "title": "Landcode",
        "description": "The country code of the person",
        "pattern": /^[A-Z]{2}$/,
        "type": "string",
        "required": false,
    },
    {
        "field": "birthDate",
        "title": "Geboortedatum",
        "description": "The birth date of the person",
        "type": "date",
        "required": true,
    },
    {
        "field": "birthPlace",
        "title": "Geboorteplaats",
        "description": "The birth place of the person",
        "pattern": /^[a-zA-Z\u00C0-\u00FF .,'-]{2,100}$/,
        "type": "string",
        "required": false,
    },
    {
        "field": "phone",
        "title": "Telefoon",
        "description": "the phone number of the person",
        "pattern": /^[+]{0,1}[0-9- ]+$/,
        "type": "string",
        "required": false,
    },
    {
        "field": "email",
        "title": "Email",
        "description": "The email address of the person",
        "pattern": /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "type": "string",
        "format": "email",
        "required": false,
    }
]