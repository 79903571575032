<template>
    <div class="login-background">
        <div class="login-overlay">
            <div>
                <img class="logo" src="/logofull.png" alt="Logo" />
            </div>
            <div class="login-form-frame">
                <h3>Sign in</h3>
                <div class="progress-bar-container">
                    <div class="progress-bar auto-start" ref="progressBar"></div>
                </div>
                <h1>Login</h1>
                <p>Enter your email below. You will then receive a one-time login code.</p>
                <div class="login-form">
                    <form @submit.prevent="login">
                        <div class="login-form-group">
                            <label for="email" required >Email address</label>
                            <input type="email" id="email" v-model="email" placeholder="example@email.com" required :class="{ inputerror: emailError ? true : false }"/>
                            <p class="email-error" v-if="emailError">{{ emailError }}</p>
                            <p class="email-error" v-if="emailError === 'This email address is not registered.'">Please go to
                                <router-link to="/register" class="register-button">Register</router-link>
                            </p>
                        </div>
                        <button type="submit" class="btn">
                            Send
                            <i v-html="$icon('paper-airplane')"></i>
                        </button>
                    </form>
                    <p class="suggestion">
                        Don't have an account yet? Then go to
                        <router-link to="/register" class="register-button">Register</router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'login',
    components: {
    },
    data() {
        return {
            email: "",
            emailError: ""
        }
    },
    watch: {
        email() {
            if (this.emailError) {
                this.emailError = ""
            }
        }
    },
    mounted() {
        this.startProgressBar();
    },
    methods: {
        startProgressBar() {
            const progressBar = this.$refs.progressBar;
            progressBar.style.width = '0%';

            progressBar.classList.add('auto-start-progress');

            setTimeout(() => {
                progressBar.style.width = '30%';
            }, 100);
        },
        login() {
            if (!this.validateEmail(this.email)) {
                return
            }
            this.$store.dispatch("login", this.email).then(() => {
                this.$router.push({ name: "otp" })
            }).catch((err) => {
                console.error(err)

                if (err?.response?.status === 404) {
                    this.emailError = "This email address is not registered."
                    return
                }
                if (err?.response?.status === 400) {
                    this.emailError = "Please enter a valid email address"
                    return
                }

                this.emailError = "Something went wrong. Please try again later"
                return
            })
        },
        validateEmail(email) {
            if (!email) {
                this.emailError = "Please enter your email address"
                return false
            }
            const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

            if (!emailRegex.test(email)) {
                this.emailError = "Please enter a valid email address. Example: example@example.com"
                return false
            }
            return true
        },
    }
}
</script>

<style lang="scss">
.login-background {
    background-image: url("@/assets/background.png");
    background-color: var(--bg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    .logo {
        width: 100%;
        max-width: 200px;
        margin-bottom: 20px;
        font-size: 2rem;
    }

    .login-form-frame {
        background-color: #FAF7FC;
        border-radius: 24px;
        box-shadow: 0px 12px 30px rgba(0, 12, 40, 0.144);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 600px;

        h3 {
            margin-top: 20px;
            text-align: center;
            font-weight: 600;
        }

        h1 {
            margin-top: 20px;
            text-align: center;
            font-weight: 600;
        }

        .login-form {
            padding: 40px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 40px;
        }
    }

    .login-form-group {
        width: 100%;
        
        
        label {
            font-weight: 600;
            margin-bottom: 5px;
            display: block;
        }

        input {
            width: 100%;
            padding: 15px;
        }
        
        .inputerror{
            border-color: var(--error-color);
            background-color: #FFDADA;
        }

        .email-error {
            color: var(--error-color);
            font-size: 0.8rem;
            margin-top: 5px;
        }
    }

    .suggestion {
        text-align: center;
        font-weight: 600;
        color: #C7C7D1;
    }

    .register-button {
        color: #1C53D7;
        text-decoration: underline;
        cursor: pointer;
        text-decoration: none;
    }

    .progress-bar-container {
        margin-top: 10px;
        width: 100%;
        height: 3px;
        background-color: #F1EDF5;
    }

    .progress-bar {
        width: 0%;
        height: 100%;
        background-color: #1C53D7;
    }

    @keyframes auto-start-progress {
        0% {
            width: 0;
        }

        100% {
            width: 30%;
        }
    }

    .progress-bar.auto-start {
        animation: auto-start-progress 2s ease-in-out;
    }

    .btn {
        margin-top: 40px;
    }


}
</style>