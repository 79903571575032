<template>
    <popup @close="close" :width="600" :error="error">
        <template v-slot:header>
            Bestand Uploaden
        </template>

        <template v-slot:content>
            <div :class="['field', 'select', {'error': errors.template}]">
                <label>Kies een Sjabloon</label>
                <div class="selectwrapper">
                    <select v-model="template">
                        <option value="">- Kies een Sjabloon -</option>
                        <option v-for="(tmpl, idx) in templatesFiltered" :key="tmpl.id" :value="tmpl" :selected="idx == 0" v-text="tmpl.name"></option>
                    </select>
                </div>
                <p class="error">Kies een Sjabloon</p>
                <p class="info">Zie je geen Sjabloon? <router-link to="/templates" class="link-text">Maak er hier een aan</router-link>.</p>
            </div>

            <div :class="['field', {'error': errors.file}]">
                <label>Kies een CSV bestand</label>
                <fileselect @selected="parseFile" @error="errors.file = 'Geen bestand geselecteerd'"></fileselect>
                <p class="error">{{errors.file}}</p>
            </div>
        </template>

        <template v-slot:footer>
            <button class="light" @click="close"><i v-html="$icon('no-symbol')"></i>Annuleren</button>
            <button @click="next"><i v-html="$icon('book-open')"></i>Bestand Bekijken</button>
        </template>

     </popup>
</template>

<script>

import Papa from 'papaparse'
import dayjs from 'dayjs';
// import customParseFormat from 'dayjs/plugin/customParseFormat';

import popup from '@/components/popup.vue'
import fileselect from '@/components/fileselect.vue'

export default {
    name: 'popup-select-file',
    props: {
        recipientType: {
            type: String,
            default: "person"
        }
    },
    components: {
        popup,
        fileselect
    },
    computed: {
        templates() {
            return this.$store.getters.templates
        },
        templatesFiltered() {
            return this.recipientType && this.templates.length ? this.templates.filter(template => template.recipientType === this.recipientType) : []
        },
    },
    data() {
        return {
            template: null,
            file: null,
            data: null,
            fileName: "",
            error: false,
            errors: {}
        }
    },
    methods: {
        close(){
            this.$emit("close")
        },
        next(){
            this.errors.template = !this.template
            this.errors.file = !this.data ? "Geen bestand geselecteerd" : ""

            if(!this.errors.template && !this.errors.file){
                this.error = false
                this.$emit("update:model-value", {data: this.data, template: this.template})
                this.$emit("ready")
            }else{
                this.error = true
            }
        },
        parseFile(file){
            // Remove empty lines (also at the end)
            file = file.split(/\r?\n/).filter(line => line.trim() !== '').join('\n')

            const json = Papa.parse(file, { header: true })

            console.log(json)

            if (json.errors.length) {
                console.error("Papa parse errors: ", json)
                this.errors.file = `Error parsing data: ${json.errors.join(" - ")}`
                this.data = null
            } else {
                this.errors.file = false
                this.data = json.data
            }
        }
    }
}
</script>
<style lang="scss" scoped>
</style>