<template>
    <div class="page">
        <popup-select-file v-if="showPopup == 'select-file'" v-model="popupSelectFileData" :recipientType="recipientType" @ready="showPopup = 'parse-file'" @close="showPopup = ''"></popup-select-file>
        <popup-parse-file v-if="showPopup == 'parse-file'" :template="popupSelectFileData.template" :data="popupSelectFileData.data" @refresh="refresh" @close="showPopup = ''" @goback="showPopup = 'select-file'"></popup-parse-file>

        <h1>{{pageTitle}}</h1>

        <section class="white">
            <button @click="showPopup = 'select-file'"><i v-html="$icon('cloud-arrow-up')"></i>Registraties {{pageTitle}} Uploaden</button>
        </section>

        <section class="white overflow" v-if="!loading">
            <rtable :columns="columns" :data="data" :clickable="true"></rtable>
        </section>

        <div class="loader" v-if="loading"><i v-html="$icon('arrow-path')"></i></div>

    </div>

</template>

<script>

import rtable from '@/components/rtable.vue'

import personSettings from '@/settings/persons.js'
import branchSettings from '@/settings/branches.js'
import organizationSettings from '@/settings/organizations.js'

import popupSelectFile from '@/components/popup-select-file.vue'
import popupParseFile from '@/components/popup-parse-file.vue'

export default {
    name: 'upload',
    components: {
        rtable,
        popupSelectFile,
        popupParseFile
    },
    computed: {
        headerAuth() {
            return this.$store.getters.headerAuth
        },
        columns() {
            if(this.loading){
                return []
            }
            if(this.recipientType == "persons"){
                return personSettings.filter(setting => !setting.hide).map(setting => {
                    const field = setting.field == "referenceRecipient" ? "reference" : setting.field
                    return {
                        title: setting.title,
                        field: field
                    }
                })
            }
            if(this.recipientType == "branches"){
                return branchSettings.filter(setting => !setting.hide).map(setting => {
                    const field = setting.field == "referenceRecipient" ? "reference" : setting.field
                    return {
                        title: setting.title,
                        field: field
                    }
                }).concat([{title: "Status", field: "recipientStatus", type: "labels"}])
            }
            if(this.recipientType == "organizations"){
                return organizationSettings.filter(setting => !setting.hide).map(setting => {
                    const field = setting.field == "referenceRecipient" ? "reference" : setting.field
                    return {
                        title: setting.title,
                        field: field
                    }
                }).concat([{title: "Status", field: "recipientStatus", type: "labels"}])
            }
        },
        activeMe() {
            return this.$store.getters.activeMe || ""
        },
        recipientType(){
            return this.$route.meta.recipientType || ""
        },
        pageTitle(){
            return this.$route.meta.title
        }
    },
    watch: {
        recipientType() {
            if(this.recipientType){
                this.tabledata = null
                this.getRecipients()
            }
        },
    },
    data() {
        return {
            loading: true,
            showPopup: "",
            popupSelectFileData: null,
            error: "",
            data: [],
        }
    },
    mounted(){
        this.getRecipients()
    },
    methods: {
        refresh(){
            this.getRecipients()
        },
        async getRecipients() {
            try {
                this.loading = true
                const response = await this.$http.get(`/recipients/${this.recipientType}`, this.headerAuth)
                const data = response?.data[this.recipientType] || []
                this.data = data.map(row => {
                    row.recipientStatus = (this.recipientType == "branches" || this.recipientType == "organizations") && row.deregistrationDate ? [{color: "red", value: "Uitgeschreven"}] : []
                    return row
                })
                this.loading = false
            } catch (error) {
                console.error(error)
                this.error = error?.response?.data?.description || error.toString()
            }
        }
    }
}
</script>

<style lang="scss">
.loader {
    display: flex;

    i {
        width: 40px;
        height: 40px;
    }
}
</style>