<template>
    <section>
        <div>
            <h1>Home</h1>
        </div>
    </section>
</template>
<script>
export default {
    name: 'home',
    components: {
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
    }
}
</script>
<style lang="scss">

</style>