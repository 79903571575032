export default [
    {
        "id": "438a5366-1e52-4658-8977-c0dba863b3d8",
        "name": "CRT Persoon Origineel",
        "recipientType": "persons",
        "resources": ["persons", "registrations"],
        "settings": {
            "referenceRecipient": "IDP",
            "initials": "Voorletters",
            "firstName": "Voornaam",
            "lastName": "[Voorvoegsel] ? [Voorvoegsel] + '|' + [Achternaam] : [Achternaam]",
            "birthDate": "[Geboortedatum]:YYYYMMDD",
            "birthPlace": "Geboorteplaats",
            "countryCode": "Land",
            "phone": "Telefoonnummer",
            "email": "Email",
            "postalCode": "Postcode",
            "houseNumber": "Huisnummer",
            "houseNumberPostfix": "Toevoeging",
            "definitionCrtId": "Kwalificatietype",
            "referenceRegistration": "ID",
            "number": "Examennummer",
            "startDate": "[Startdatum]:YYYYMMDD",
            "evaluationDate": "[Toetsingsdatum]:YYYYMMDD",
            "endDate": "[Einddatum]:YYYYMMDD",
            "revocationDate": "[Status] == '3' ? TODAY : null",
        }
    },
    {
        "id": "b7a18eb7-4030-4834-b025-66df6b325ac3",
        "name": "CRT Vestiging Origineel",
        "recipientType": "branches",
        "resources": ["branches", "registrations"],
        "settings": {
            "tradeName": "Handelsnaam",
            "cocNumber": "KvKnummer",
            "cocBranchNumber": "Vestigingsnummer",
            "city": "Vestigingsplaats",
            "phone": "Telefoonnummer",
            "email": "Email",
            "definitionCrtId": "Kwalificatietype",
            "referenceRegistration": "ID",
            "startDate": "[Startdatum]:YYYYMMDD",
            "evaluationDate": "[Toetsingsdatum]:YYYYMMDD",
            "endDate": "[Einddatum]:YYYYMMDD",
            "revocationDate": "[Status] == '3' ? TODAY : null",
        }
    },
    {
        "id": "404671e3-d303-4553-9ed6-fb08213eb034",
        "name": "CRT Persoon Nieuw",
        "recipientType": "persons",
        "resources": ["persons", "registrations"],
        "settings": {
            "referenceRecipient": "personIdExternal",
            "initials": "personInitials",
            "firstName": "personFirstName",
            "lastName": "[personMiddleName] ? [personMiddleName] + '|' + [personLastName] : [personLastName]",
            "birthDate": "[personBirthDate]:YYYY-MM-DD",
            "birthPlace": "personBirthPlace",
            "countryCode": "personCountryCode",
            "email": "personEmail",
            "postalCode": "personPostalCode",
            "houseNumber": "personHouseNumber",
            "houseNumberPostfix": "personHouseNumberPostfix",
            "definitionId": "qualificationUuid",
            "definitionCrtId": "qualificationId",
            "referenceRegistration": "registrationIdExternal",
            "number": "registrationExamNumber",
            "startDate": "[registrationStartDate]:YYYY-MM-DD",
            "evaluationDate": "[registrationEvaluationDate]:YYYY-MM-DD",
            "endDate": "[registrationEndDate]:YYYY-MM-DD",
            "revocationDate": "[registrationSuspended] == '1' || [registrationSuspended] == 'true' || [registrationSuspended] ? TODAY : null",
        }
    },
    {
        "id": "5d504d75-04b8-4c40-bffc-f206af8c5aea",
        "name": "OutSystems Export Persoon",
        "recipientType": "persons",
        "resources": ["persons", "registrations"],
        "settings": {
            "referenceRecipient": "schemaPersonId",
            "initials": "initials",
            "firstName": "firstName",
            "lastName": "[middleName] ? [middleName] + '|' + [lastName] : [lastName]",
            "postalCode": "postalCode",
            "houseNumber": "houseNumber",
            "houseNumberPostfix": "houseNumberPostfix",
            "countryCode": "countryCode",
            "birthDate": "[dateOfBirth]:YYYY-MM-DD",
            "birthPlace": "placeOfBirth",
            "phone": "phone",
            "email": "email",
            "referenceRegistration": "externalId",
            "definitionCrtId": "qualificationTypeId",
            "number": "examinationNumber",
            "startDate": "[startDate]:YYYY-MM-DD",
            "endDate": "[endDate]:YYYY-MM-DD",
            "evaluationDate": "[examinationDate]:YYYY-MM-DD",
            "revocationDate": "[status] == 3 ? [lastModifiedDate]:YYYY-MM-DD : null"
        }
    },
    {
        "id": "be509e77-82a6-4a1e-b51c-903b58e4b6c6",
        "name": "OutSystems Export Vestiging",
        "recipientType": "branches",
        "resources": ["branches", "registrations"],
        "settings": {
            "cocNumber": "cocNumber",
            "cocBranchNumber": "cocBranchNumber",
            "tradeName": "tradeName",
            "city": "city",
            "phone": "phone",
            "email": "email",
            "referenceRegistration": "externalId",
            "definitionCrtId": "qualificationType",
            "startDate": "[startDate]:YYYY-MM-DD",
            "evaluationDate": "[examinationDate]:YYYY-MM-DD",
            "endDate": "[endDate]:YYYY-MM-DD",
            "revocationDate": "[status] == '3' ? [lastModifiedDate]:YYYY-MM-DD : null"
        }
    }
]