import { createApp } from 'vue'

import App from './app.vue'
import router from './router.js'
import store from './store.js'

Object.defineProperty(Array.prototype, "first", { enumerable: false, value: function(def) { return this && this.length ? this[0] : def ? def : null } })

import axios from './axios.js'


// Hero Icons
import hero24outline from "heroicons/vue/hero24outline.js"
import hero24solid from "heroicons/vue/hero24solid.js"

const hero = {hero24outline, hero24solid}
const hero_notfound = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>`

const icon = (name, type = "outline", size = 24) => {
	return hero[`hero${size}${type}`] ? hero[`hero${size}${type}`][name] || hero_notfound : hero_notfound
}

const app = createApp(App)

app.config.globalProperties.$http = axios
app.config.globalProperties.$icon = icon

import rtable from '@/components/rtable.vue'

app.component('rtable', rtable)

app.use(store).use(router).mount('#app')