<template>
    <div class="ctable">
        <table>
            <tbody>
                <tr v-for="row in rows">
                    <td class="header">
                        <span v-text="row.title"></span>
                    </td>
                    <td>
                        <template v-if="row.type == 'label'">
                            <span :class="['label', data[row.field].first('').toLowerCase()]" v-text="data[row.field].first('')"></span>
                        </template>
                        <template v-if="row.type == 'labels'">
                            <span class="label" v-for="label in data[row.field]" v-text="label"></span>
                        </template>
                        <template v-else-if="row.type == 'url'">
                            <a :href="data[row.field]" v-text="data[row.field]" target="_blank"></a>
                        </template>
                        <template v-else>
                            <span v-text="data[row.field]"></span>
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
// import dayjs from 'dayjs'

export default {
    name: 'ctable',
    props: {
        data: {
            type: Object,
            default: () => { return {} }
        },
        rows: {
            type: Array,
            default: () => { return [] }
        }
    },
    computed: {
    },
    data() {
        return {}
    },
    methods: {
        route(row) {
            if (row.id) {
                this.$router.push(this.$route.path + "/" + row.id)
            }
        },
    }
}
</script>
<style lang="scss">
.ctable {
    table {
        tbody {

            tr {
                border-bottom: 1px solid var(--bg);

                td {
                    text-align: left;
                    line-height: 40px;
                    padding: 0 10px;

                    &.header {
                        font-weight: 600;
                        width: 200px;
                    }

                    span {

                        i {
                            display: block;
                            width: 25px;

                            &.true {
                                color: #27ae60;
                            }

                            &.false {
                                color: #e74c3c;
                            }
                        }
                    }

                    .label {
                        font-size: 14px;
                        padding: 2px 6px;
                        background-color: var(--blue);
                        border-radius: 6px;
                        color: #FFF;
                        font-weight: 600;
                        margin-right: 4px;

                        &.actief {
                            background-color: #27ae60;
                        }

                        &.verlopen, &.uitgeschreven {
                            background-color: #e74c3c;
                        }
                    }
                }
            }
        }
    }
}
</style>