<template>
    <aside class="aside">
        <router-link to="/persons">
            <div class="logo">
                <img src="/logofull.png">
            </div>
        </router-link>
        <div class="divider"></div>
        <ul>
            <li v-for="item in menu">
                <div v-if="item.type == 'h'" class="h" v-text="item.title"></div>
                <router-link v-else :to="item.url || ''" :class="[{ 'router-link-active': isActive(item.url) }]">
                    <i v-html="$icon(item.icon)"></i>
                    <span v-text="item.title"></span>
                </router-link>
            </li>
        </ul>
        <ul class="bottom">
            <li v-for="item in settingsMenu">
                <div v-if="item.type == 'h'" class="h" v-text="item.title"></div>
                <router-link v-else :to="item.url || ''" :class="[{ 'router-link-active': isActive(item.url) }]">
                    <i v-html="$icon(item.icon)"></i>
                    <span v-text="item.title"></span>
                </router-link>
            </li>
            <!-- <p class="subtitle">Version 0.1.xx</p> -->
            <!-- <p class="subtitle">Centraal Register Techniek</p> -->
        </ul>
    </aside>
</template>
<script>
export default {
    name: 'sidebar',
    props: {
    },
    computed: {
        activeMe() {
            return this.$store.getters.activeMe
        },
        me() {
            return this.$store.getters.me
        },
        authorizations() {
            return this.$store.getters.authorizations
        },
    },
    data() {
        return {
            menu: [
                // {
                //     title: "Wallet",
                //     type: "h"
                // },
                // {
                //     title: "Wallet",
                //     url: "/persons",
                //     icon: "wallet",
                // },
                // {
                //     title: "Discover",
                //     url: "/persons",
                //     icon: "sparkles",
                // },
                // {
                //     title: "Arrangements",
                //     type: "h"
                // },
                // {
                //     title: "My arrangements",
                //     url: "/persons",
                //     icon: "calendar",
                // },
                // {
                //     title: "Authorized accounts",
                //     url: "/persons",
                //     icon: "users",
                // },
                {
                    title: "Registraties",
                    type: "h"
                },
                {
                    title: "Personen",
                    url: "/persons",
                    icon: "users",
                },
                {
                    title: "Vestigingen",
                    url: "/branches",
                    icon: "building-office",
                },
                {
                    title: "Organisaties",
                    url: "/organizations",
                    icon: "building-office2",
                },
                // {
                //     title: "All registrations",
                //     url: "/registrations",
                //     icon: "list-bullet",
                // },
            ],
            settingsMenu: [
                {
                    title: "Beheer",
                    type: "h",
                },
                {
                    title: "Sjablonen",
                    url: "/templates",
                    icon: "clipboard-document-list",
                },
            ],
            accountPopupActive: false,
        }
    },
    mounted() {
    },
    methods: {
        displayAccountPopup() {
            this.accountPopupActive = !this.accountPopupActive
        },
        chooseAccount(token) {
            this.$store.commit('activeToken', token)
            this.$store.dispatch('getMe')
            this.accountPopupActive = false
        },
        isActive(url) {
            return this.$route.path.includes(url)
        },
        logout() {
            this.$store.dispatch('logout').then(() => {
                this.$router.push("/login")
            }).catch(err => {
                console.error(err)
            })
        }
    }
}
</script>
<style lang="scss">
aside.aside {
    background-color: var(--bg);
    position: relative;
    grid-area: sidebar;
    overflow: visible;
    padding: 0 15px;

    svg {
        position: relative;
    }

    a {
        &:hover {
            text-decoration: none;
        }
    }

    div.logo {
        img {
            width: 190px;
        }

        padding: 20px 20px 20px 20px;

    }

    div.divider {
        height: 1px;
        background-color: #EAEAEA;
        margin: 0 20px;
    }




    .reddot:before {
        display: block;
        position: absolute;
        width: 5px;
        height: 5px;
        background-color: red;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            margin: 0;
            padding: 0;
            line-height: 40px;

            .spacer {
                width: 30px;
                display: inline-block;
            }

            &.divider {
                height: 10px;
                border-bottom: var(--divider);
                margin: 0 30px 10px 30px;
            }

            a,
            div {
                text-decoration: none;
                display: block;
                width: 100%;
                color: #333;
                border-radius: 5px;
                line-height: 40px;
                color: var(--menutext);
                font-family: 'Open Sans', sans-serif;
                font-weight: 500;
                width: 100%;
                padding: 0 15px;

                i {
                    width: 30px;
                    height: 40px;
                    line-height: 40px;
                    display: inline-block;

                    svg {
                        width: 25px;
                        text-align: center;
                    }
                }

                span {
                    padding-left: 15px;
                }

                &:hover {
                    color: var(--blue);
                    ;
                }

                &.router-link-active {
                    color: var(--blue);
                    font-weight: 800;

                    i {
                        svg {
                            stroke-width: 2;
                        }
                    }
                }

                &.h {
                    padding: 0 10px;
                    line-height: 30px;
                    margin-top: 20px;
                    font-weight: 800;
                    font-size: 1em;
                }
            }
        }

        &.bottom {
            position: absolute;
            bottom: 20px;
            width: 250px;
            display: block;

            .subtitle {
                margin: 10px 0 0 10px;
                color: #999;

                + .subtitle {
                    margin-top: 0px;
                }
            }
        }
    }
}

</style>