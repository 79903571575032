<template>
    <div class="rtable">
        <table :class="[{ 'clickable': clickable }]">
            <thead>
                <tr>
                    <th v-for="column in columns" :key="column.title" v-text="column.title"
                        :class="[column.type || '']"></th>
                    <th v-if="data.some(row => Object.values(row).some(subObj => subObj && subObj.error !== undefined))">
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, idx) in data" :key="row.id" :class="row._color" @click="click(row.id)">
                    <td v-for="column in columns" :key="column.field"
                        :class="[row[column.field]?.error ? 'error' : '', column.type || '']">
                        <template v-if="column.type == 'select' && row[column.field]?.options?.length">
                            <select @change="selectChange(idx, row[column.field]?.field, $event)">
                                <option v-for="option in row[column.field].options" :key="option.key"
                                    :value="option.key" v-text="option.value"
                                    :selected="option.key == row[column.field].selected">
                                </option>
                            </select>
                        </template>
                        <template v-else-if="column.type == 'labels'">
                            <span v-for="label in row[column.field]" :key="label.value" :class="['label', label.color]"
                                v-text="label.value" :alt="label.value" :title="label.value"></span>
                        </template>
                        <template v-else-if="column.type == 'boolean'">
                            <span v-if="row[column.field]"><i v-html="$icon('check')"></i></span>
                            <span v-else><i v-html="$icon('no-symbol')"></i></span>
                        </template>
                        <template v-else-if="typeof row[column.field] === 'object' && row[column.field] !== null">
                            <div class="flex">
                                <span class="message">{{ row[column.field]?.error }}</span>
                                <input
                                    v-if="editIndex === idx && column.field !== 'labels' && column.field !== 'select'"
                                    class="value edit-field" v-model="row[column.field].value"
                                    @keyup.enter="onEnterSaveEdit(idx, column.field)" />
                            </div>
                        </template>
                        <template v-else>
                            <input v-if="editIndex === idx" class="value edit-field" v-model="row[column.field]"
                                @keyup.enter="onEnterSaveEdit(idx, column.field)" />
                            <span v-else class="value">{{ typeof row[column.field] === 'object' ?
                                row[column.field]?.value : row[column.field] }}</span>
                        </template>
                    </td>
                    <td v-if="Object.values(row).some(subObj => subObj && subObj.error !== undefined)">
                        <button class="light" @click="editRow(idx)"><i v-html="$icon('pencil-square')"></i>Edit</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'rtable',
    props: {
        columns: {
            type: Array,
            default: () => []
        },
        data: {
            type: Array,
            default: () => []
        },
        clickable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editIndex: null
        }
    },
    methods: {
        selectChange(index, field, event) {
            const value = event.target.value;
            this.$emit("change", { index, field, value });
        },
        click(id) {
            if (this.clickable) {
                this.$router.push({ path: this.$route.path + "/" + id });
            }
        },
        editRow(index) {
            this.editIndex = index;
        },
        onEnterSaveEdit(index, field) {
            this.saveEdit(index, field);
        },
        saveEdit(index, field) {
            if (this.data[index][field] != null) {
                const value = typeof this.data[index][field] === 'object' ? this.data[index][field].value : this.data[index][field];
                this.$emit("update", { index, field, value });
            }
            this.editIndex = null;
        }
    }
}
</script>

<style lang="scss">
.rtable {
    overflow-x: auto;
    overflow-y: auto;
    white-space: nowrap;
    height: 100%;

    table {
        width: 100%;

        th,
        td {
            text-align: left;
            padding: 0px 10px;
            vertical-align: middle;
            line-height: 50px;
            height: 50px;
            line-height: 70px;
            height: 70px;

            &.boolean {
                width: 60px;

                i {
                    width: 30px;
                    display: block;
                }
            }
        }

        thead {
            font-weight: 700;

            tr {
                border-bottom: 1px solid var(--bg);

                th {
                    position: sticky;
                    top: 0;
                    background-color: #FFF;
                }
            }
        }

        tbody {
            tr {
                &.green {
                    background-color: #2ecc7111;
                }

                &.red {
                    background-color: #e74c3c11;
                }

                &.orange {
                    background-color: #f39c1211;
                }

                &.blue {
                    background-color: #1C53D711;
                }

                border-bottom: 1px solid var(--bg);

                td {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    .flex {
                        display: flex;
                        flex-direction: column;

                        span.message {
                            line-height: 15px;
                            font-size: 9px;
                            color: #e74c3c;
                            text-transform: uppercase;
                            font-weight: 700;
                        }

                        span.value {
                            line-height: 25px;
                        }
                    }

                    &.error {
                        background-color: #e74c3c33;
                        color: #e74c3c;
                    }

                    span.label {
                        font-size: 14px;
                        background-color: #EEE;
                        border-radius: 10px;
                        color: #FFF;
                        font-weight: 600;
                        padding: 0px 8px;
                        margin-right: 4px;
                        max-width: 400px;
                        display: inline-block;
                        height: 20px;
                        line-height: 20px;
                        vertical-align: middle;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &.green {
                            background-color: #00C38C;
                        }

                        &.red {
                            background-color: #BE0037;
                        }

                        &.orange {
                            background-color: #F0AD00;
                        }

                        &.blue {
                            background-color: #1C53D7;
                        }
                    }

                    .dropdown {
                        position: relative;
                        display: inline-block;
                        height: 100%;

                        select {
                            width: 300px;
                            overflow: hidden;
                        }
                    }

                    .dropdown::after {
                        content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27none%27%20viewBox%3D%270%200%2024%2024%27%20stroke-width%3D%272%27%20stroke%3D%27currentColor%27%20class%3D%27w-6%20h-6%27%3E%3Cpath%20stroke-linecap%3D%27round%27%20stroke-linejoin%3D%27round%27%20d%3D%27M19.5%208.25l-7.5%207.5-7.5-7.5%27%20%2F%3E%3C%2Fsvg%3E");
                        position: absolute;
                        top: 9px;
                        right: 8px;
                        width: 20px;
                        height: 20px;
                        text-align: center;
                        pointer-events: none;
                    }
                }
            }
        }

        &.clickable {
            tbody {
                tr {
                    cursor: pointer;

                    &:hover {
                        background-color: rgb(243, 244, 247);
                    }
                }
            }
        }
    }

    .edit-field {
        width: 100%;
        min-width: 200px;
    }
}
</style>
