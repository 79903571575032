<template>
    <div class="fileselect" @click="triggerFileInput" @drop.prevent="handleFile" @dragover.prevent="handleDragOver" @dragleave.prevent="handleDragLeave">

        <input type="file" ref="file" accept=".csv" @change="handleFile" hidden>

        <p class="filename" v-if="fileName">{{ fileName }}</p>

        <i class="file-icon" v-html="$icon('document-arrow-up')"></i>

        <p>
            <span v-if="!fileName" class="link">Kies een bestand</span>
            <span v-else class="link">Kies ander een bestand</span>
        </p>
        <p>Of drag & drop deze hier</p>

    </div>

</template>

<script>
export default {
    name: 'fileselect',
    props: {
        columns: {
            type: Array,
            default: () => { return [] }
        },
        data: {
            type: Array,
            default: () => { return [] }
        },
        clickable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fileName: ""
        }
    },
    methods: {
        handleFile(event) {
            event.preventDefault()

            const files = event.target.files || event.dataTransfer.files || this.$refs.file.files
            const file = files?.length ? files[0] : null

            if(!this.fileName && !file){
                this.$emit("error")
                return
            }

            if(file) {
                this.fileName = file?.name || ""

                const reader = new FileReader()

                reader.onload = (file) => {
                    this.$emit("selected", file.target.result)
                }

                reader.onerror = (error) => {
                    console.error(error)
                    this.error.file = error.toString()
                }

                reader.readAsText(file, "UTF-8")
            }
        },
        triggerFileInput() {
            this.$refs.file.click()
        },

    }
}
</script>
<style lang="scss" scoped>
.fileselect {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border: 2px dashed #E5E4EB;
    background-color: #FFF;
    border-radius: 20px;
    width: 100%;
    text-align: center;
    padding: 30px;
    transition: border .3s ease-in-out;

    p {
        margin: 0;
        // font-size: 14px;
        color: #262E40;

        &.filename {
            margin-bottom: 20px;
            font-weight: 700;
        }
    }

    .link {
        font-weight: 700;
        color: var(--blue);
    }

    i {
        margin: 0 auto;
        color: #262E40;
        margin-bottom: 10px;
        width: 50px;
    }
}
</style>