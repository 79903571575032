<template>
    <popup @cancel="close">
        <template v-slot:header>
            Controleer bestand
        </template>
        <template v-slot:content>
            <section class="table-frame">
                <rtable :columns="columns" :data="tabledata" @change="setValue" @update="updateValue"></rtable>
            </section>
        </template>
        <template v-slot:footer>
            <button v-if="!isDone" @click="goback" class="light"><i v-html="$icon('arrow-left')"></i>Terug</button>
            <button v-if="!isDone" @click="close" class="light"><i v-html="$icon('no-symbol')"></i>Annuleren</button>
            <button v-if="hasSubmittable" @click="submitData" class="positive-action-button"><i
                    v-html="$icon('arrow-up-tray')"></i>Toevoegen</button>
            <button v-if="!isDone && hasDone" @click="removeSuccesfulData" class="light"><i
                    v-html="$icon('check-circle')"></i>Opschonen</button>
            <button v-if="isDone" @click="closeAndRefresh"><i v-html="$icon('check-circle')"></i>Klaar</button>
        </template>
    </popup>
</template>

<script>
import rtable from '@/components/rtable.vue'
import popup from '@/components/popup.vue'
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat)

import { getSettings } from "@/settings.js"

import personSettings from '@/settings/persons.js'
import branchSettings from '@/settings/branches.js'
import organizationSettings from '@/settings/organizations.js'
import registrationSettings from '@/settings/registrations.js'

function isEqual(var1, var2) {
    return var1 === var2 || (!var1 && !var2)
}

function makeUrlQuery(queryObj) {
    return typeof queryObj === "object" ? "?" + Object.keys(queryObj).map(key => key + "=" + queryObj[key]).join("&") : ""
}

const colors = {
    "error": "red",
    "warning": "orange",
    "ready": "blue",
    "done": "green",
}

export default {
    name: 'popup-parse-file',
    props: {
        template: {
            type: Object,
            default: () => ({})
        },
        data: {
            type: Array,
            default: () => []
        }
    },
    components: {
        rtable,
        popup
    },
    computed: {
        headerAuth() {
            return this.$store.getters.headerAuth
        },
        settings() {
            return getSettings(this.template?.resources || [])
        },
        columns() {
            const columns = this.settings?.map(setting => {
                return { title: setting.title, field: setting.field }
            }) || []

            return [{ title: "Status", field: "labels", type: "labels" }, { title: "", field: "select", type: "select" }].concat(columns)
        },
        recipientType() {
            return this.template.recipientType
        },
        tabledataStatus() {
            return this.tabledata.reduce((acc, cur) => {
                acc[cur.status] = acc[cur.status] ? acc[cur.status] + 1 : 1
                acc.total++
                return acc
            }, { "total": 0 })
        },
        isDone() {
            return this.tabledataStatus.done === this.tabledataStatus.total
        },
        hasDone() {
            return this.tabledataStatus.done > 0
        },
        hasSubmittable() {
            return this.tabledataStatus.ready > 0 || this.tabledataStatus.warning > 0
        }
    },
    data() {
        return {
            tabledata: [],
            definitions: []
        }
    },
    async mounted() {
        this.tabledata = this.parseData()
        await this.checkDefinitions()
        this.checkRows()
    },
    methods: {
        reset() {
            this.tabledata = [];
        },
        close() {
            this.reset();
            this.$emit("close");
        },
        closeAndRefresh() {
            this.$emit("refresh");
            this.close();
        },
        goback() {
            this.$emit("goback");
        },
        setValue(data) {
            if (data.field) {
                this.tabledata[data.index][data.field] = data.value;
            }
        },
        updateValue({ index, field, value }) {
            this.tabledata[index][field] = value;
            this.checkRow(this.tabledata[index]);
        },
        checkRows() {
            for (let index = 0; index < this.tabledata.length; index++) {
                this.checkRow(this.tabledata[index]);
            }
        },
        validateRow(row) {
            row.status = "";
            return this.settings.reduce((hasError, setting) => {
                const fieldName = setting.field;
                let value = row[fieldName];

                if (value && typeof value === 'object' && value.error) {
                    return true;
                }

                if (setting.type === "date" && typeof value === 'string' && value) {
                    const splitted = value.split(":");
                    const sourceDate = splitted[0];
                    const sourceFormat = splitted.length > 1 ? splitted[1] : "YYYY-MM-DD";

                    if (sourceDate && sourceDate !== "undefined") {
                        const parsedDate = dayjs(sourceDate.replace(/\s+/g, ""), sourceFormat, true);
                        if (!parsedDate.isValid()) {
                            row[fieldName] = { value: value, error: "Invalid date" };
                            return true;
                        } else {
                            row[fieldName] = parsedDate.format("YYYY-MM-DD");
                        }
                    } else {
                        row[fieldName] = value || null;
                    }
                } else if (setting.type === "boolean") {
                    value = typeof value === "string" ? value.toLowerCase() === "true" : value === true;
                    row[fieldName] = value;
                } else if (fieldName === "cocBranchNumber" && typeof value === 'string' && value) {
                    if (value.length < 12) {
                        row[fieldName] = value.padStart(12, "0");
                    }
                } else if (fieldName === "initials" && typeof value === 'string' && value) {
                    row[fieldName] = value.replace(/\.|\s/g, "");
                } else if (fieldName === "email" && typeof value === 'string' && value) {
                    row[fieldName] = value.toLowerCase();
                } else if (setting.pattern && value && !setting.pattern.test(value)) {
                    row[fieldName] = { value: value, error: "Syntax Fout" };
                    return true;
                } else if (setting.required && (!value && value !== false)) {
                    row[fieldName] = { value: value, error: "Verplicht veld" };
                    return true;
                } else {
                    row[fieldName] = value;
                }

                return hasError;
            }, false);
        },
        checkRow(row) {
            row.labels = row.labels.filter(label => label.value !== "Gegevens moet worden gecorrigeerd" && label.value !== "Klaar voor toevoegen");
            const hasError = this.validateRow(row);

            if (hasError) {
                row.status = "error";
                row.labels.push({ value: "Gegevens moet worden gecorrigeerd", color: "red" });
            }

            if (row.status !== "error" && row.status !== "done") {
                row.labels.push({ value: "Klaar voor toevoegen", color: "blue" });
                row.status = "ready";
            }

            row._color = colors[row.status];
        },
        parseData() {
            let tabledata = this.data.map(row => {
                return this.parseDataRow(row);
            });

            return tabledata;
        },
        parseDataRow(row) {
            let mappedRow = this.settings.reduce((accumulator, setting) => {
                const fieldName = setting.field;
                const source = this.template?.settings[fieldName] || "";
                let value = null;

                if (source?.includes("[")) {
                    let evalString = source?.replaceAll("[", "__row__['");
                    evalString = evalString.replaceAll("]", "']");
                    evalString = evalString.replaceAll(/:[a-zA-Z/\\\-]{6,12}/g, " + '$&'");
                    evalString = evalString.replaceAll("TODAY", `'${dayjs().format('YYYY-MM-DD')}'`);

                    try {
                        value = Function("__row__", `return ${evalString}`)(row);
                    } catch (error) {
                        console.error(error);
                        accumulator[fieldName] = { value: setting.source, error: "Syntax Fout" };
                        accumulator.status = "error";
                        return accumulator;
                    }
                } else {
                    value = row[source] || null;
                }

                if (setting.type === "date" && typeof value === 'string' && value) {
                    const splitted = value.split(":");
                    const sourceDate = splitted[0];
                    const sourceFormat = splitted.length > 0 ? splitted[1] : "YYYY-MM-DD";

                    if (sourceDate && sourceDate !== "undefined") {
                        const parsedDate = dayjs(sourceDate.replace(/\s+/g, ""), sourceFormat, true);

                        if (!parsedDate.isValid()) {
                            accumulator[fieldName] = { value: row[source], error: "Invalid date" };
                            accumulator.status = "error";
                            return accumulator;
                        } else {
                            value = parsedDate.format("YYYY-MM-DD");
                        }
                    } else {
                        value = row[source] || null;
                    }
                }

                if (setting.type === "boolean") {
                    value = typeof (value) === "string" ? value.toLowerCase() === "true" : value === true;
                }

                if (fieldName === "cocBranchNumber" && typeof value === 'string' && value) {
                    if (value.length < 12) {
                        value = value.padStart(12, "0");
                    }
                }

                if (fieldName === "initials" && typeof value === 'string' && value) {
                    value = value.replace(/\.|\s/g, "");
                }

                if (fieldName === "email" && typeof value === 'string' && value) {
                    value = value.toLowerCase();
                }

                if (setting.pattern && value) {
                    if (!setting.pattern.test(value)) {
                        accumulator[fieldName] = { value: value, error: "Syntax Fout" };
                        accumulator.status = "error";
                        return accumulator;
                    }
                }

                if (setting.required && !value && value !== false) {
                    accumulator[fieldName] = { value: accumulator[fieldName], error: "Verplicht veld" };
                    accumulator.status = "error";
                    return accumulator;
                }

                if (accumulator[fieldName] && typeof accumulator[fieldName] === 'object' && 'error' in accumulator[fieldName]) {
                    accumulator[fieldName] = value;
                } else {
                    accumulator[fieldName] = value;
                }

                return accumulator;
            }, { "status": "", "labels": [], "select": { selected: "", options: [], field: "" } });

            if (this.template.recipientType === "branches") {
                if (!mappedRow["cocNumber"] && !mappedRow["cocBranchNumber"]) {
                    mappedRow.labels.push({ value: "KvK of Vestigingsnummer verplicht", color: "red" });
                    mappedRow.status = "error";
                }
            }

            if (!mappedRow["definitionId"] && !mappedRow["definitionTitle"] && !mappedRow["definitionCrtId"]) {
                mappedRow.labels.push({ value: "De ID, titel, of CRT-ID voor Definitie verplicht", color: "red" });
                mappedRow.status = "error";
            }

            if (mappedRow["startDate"] === mappedRow["endDate"]) {
                mappedRow.labels.push({ value: "Ongeldig: start en einddatum hetzelfde", color: "red" });
                mappedRow.status = "error";
            }

            this.validateRow(mappedRow);

            return mappedRow;
        },
        async checkDefinitions() {
            for (let index = 0; index < this.tabledata.length; index++) {
                const row = this.tabledata[index]

                if (!row.definitionId) {
                    if (row.definitionCrtId) {
                        try {
                            row.definitionId = await this.getDefinitionIdByCrtId(row.definitionCrtId)
                        } catch (error) {
                            console.error(error)
                            row.definitionId = { value: row.definitionId, error: error }
                            row.labels.push({ value: "Definitie CRT ID niet gevonden", color: "red" })
                            row.status = "error"
                        }
                    }

                    if (row.definitionTitle) {
                        try {
                            row.definitionId = await this.getDefinitionIdByTitle(row.definitionTitle)
                        } catch (error) {
                            console.error(error)
                            row.definitionId = { value: row.definitionId, error: error }
                            row.labels.push({ value: "Definitie titel niet gevonden", color: "red" })
                            row.status = "error"
                        }
                    }
                }
            }
        },
        async submitData() {
            for (let index = 0; index < this.tabledata.length; index++) {
                const row = this.tabledata[index]

                if (row.status === "error" || row.status === "done") {
                    continue
                }

                row.labels = []
                console.log("submitData", row.labels)

                switch (this.template.recipientType) {
                    case 'persons':
                        if (row.recipientId && row.recipientId !== "new") {
                            row.labels.push({ color: "green", value: "Persoon ongewijzigd" })
                        }

                        if (!row.recipients) {
                            try {
                                row.recipients = await this.getPersons({ name: row.lastName, "birth-date": row.birthDate })
                            } catch (error) {
                                console.error(error)
                                row.status = "error"
                                continue
                            }
                        }

                        if (row.recipients?.length === 0 || row.recipientId === "new") {
                            try {
                                const person = await this.postPerson(this.createRequestBody(row, personSettings))
                                row.recipientId = person.id || ""
                                row.labels.push({ color: "green", value: "Persoon Toegevoegd" })
                            } catch (error) {
                                console.error(error)
                                row.labels.push({ color: "red", value: `PstPrs: ${error}` })
                                row.status = "error"
                                continue
                            }
                        }

                        if (row.recipients?.length > 0 && !row.recipientId) {
                            row.select.options = row.recipients.map(recipient => ({ key: recipient.id, value: `${recipient.initials} ${recipient.firstName ? "(" + recipient.firstName + ") " : ""}${recipient.lastName}, ${recipient.birthDate}` }))
                            row.select.options.push({ key: "new", value: "Forceer nieuw persoon" })
                            row.select.field = "recipientId"

                            if (row.recipients?.length > 1) {
                                row.labels.push({ color: "red", value: "Meerdere Matches" })
                                row.status = "warning"
                                row.select.options = [{ key: "", value: "- Selecteer Persoon -" }].concat(row.select.options)
                            }

                            if (row.recipients?.length === 1) {
                                row.labels.push({ color: "orange", value: "Persoon Match" })
                                row.status = "warning"

                                const firstRecipientId = row.recipients[0].id
                                row.select.selected = firstRecipientId
                                row.recipientId = firstRecipientId
                            }

                            continue
                        }

                        break
                    case 'branches':
                        try {
                            const branch = await this.putBranch(this.createRequestBody(row, branchSettings, ['deregistrationDate']))
                            row.recipientId = branch.id || ""
                            row.labels.push({ color: "green", value: "Vestiging Ok" })
                        } catch (error) {
                            console.error(error)
                            row.labels.push({ color: "red", value: `PutBrn: ${error}` })
                            row.status = "error"
                            continue
                        }
                        break
                    case 'organizations':
                        try {
                            const organization = await this.putOrganization(this.createRequestBody(row, organizationSettings, ['deregistrationDate']))
                            row.recipientId = organization.id || ""
                            row.labels.push({ color: "green", value: "Organisatie Ok" })
                        } catch (error) {
                            console.error(error)
                            row.labels.push({ color: "red", value: `PutOrg: ${error}` })
                            row.status = "error"
                            continue
                        }
                        break
                }

                if (row.recipientId) {
                    if (!row.registrations) {
                        try {
                            row.registrations = await this.getRegistrations(row.recipientId, { "definition-id": row.definitionId, "start-date": row.startDate })
                        } catch (error) {
                            console.error(error)
                            row.labels.push({ color: "red", value: error })
                            row.status = "error"
                            continue
                        }
                    }

                    if (row.registrations?.length > 1) {
                        row.select.options = row.registrations.map(registration => ({ key: registration.id, value: `${registration.title} @ ${registration.startDate}` }))
                        row.select.options.push({ key: "new", value: "Forceer nieuwe Registratie" })
                        row.select.field = "registrationId"

                        const firstRegistrationId = row.registrations[0].id

                        row.select.selected = firstRegistrationId
                        row.registrationId = firstRegistrationId

                        row.status = "warning"
                        continue
                    }

                    if (row.registrations?.length === 1) {
                        const curReg = row.registrations[0]
                        const newReg = this.createRequestBody(row, registrationSettings, ['startDate', 'definitionId', 'definitionTitle', 'definitionCrtId', 'referenceRegistration'])

                        row.registrationId = curReg?.id

                        if (!isEqual(curReg.endDate, newReg.endDate) || !isEqual(curReg.revocationDate, newReg.revocationDate) || !isEqual(curReg.evaluationDate, newReg.evaluationDate) || !isEqual(curReg.number, newReg.number)) {
                            try {
                                await this.patchRegistration(curReg.id, newReg)
                                row.labels.push({ color: "green", value: "Registratie Bijgewerkt" })
                                row.select.options = []
                                row.status = "done"
                            } catch (error) {
                                console.error(error)
                                row.labels.push({ color: "red", value: `PtcReg: ${error}` })
                                row.status = "error"
                                continue
                            }
                        } else {
                            row.labels.push({ color: "green", value: "Registratie Ongewijzigd" })
                            row.select.options = []
                            row.status = "done"
                        }
                    }

                    if (!row.registrations?.length) {
                        try {
                            const registrations = await this.getRegistrations(row.recipientId, { "definition-id": row.definitionId })
                            const hasActive = registrations.reduce((acc, curr) => acc || curr.status === "active", false)

                            if (hasActive) {
                                row.labels.push({ color: "red", value: "Active registratie reeds aanwezig" })
                                row.status = "error"
                                continue
                            }
                        } catch (error) {
                            console.error(error)
                            row.labels.push({ color: "red", value: error })
                            row.status = "error"
                            continue
                        }
                    }

                    if (!row.registrations?.length || row.recipientId === "new") {
                        try {
                            const newRegistration = await this.postRegistration(row.recipientId, this.createRequestBody(row, registrationSettings, ['definitionCrtId', 'definitionTitle']))
                            row.registrationId = newRegistration?.id
                            row.labels.push({ color: "green", value: "Registratie Toegevoegd" })
                            row.select.options = []
                            row.status = "done"
                        } catch (error) {
                            console.error(error)
                            row.labels.push({ color: "red", value: `PstReg: ${error}` })
                            row.status = "error"
                            continue
                        }
                    }
                }
                this.checkRow(row)
            }
        },
        async getCurrentRegistrations() {
            try {
                const response = await this.$http.get(`/registrations`, this.headerAuth)
                const data = response.data ? response.data.registrations || [] : []
                this.currentRegistrations = data.filter(el => el.recipient.type === this.template.recipientType).map(el => {
                    el.tradeName = el.recipient.tradeName || el.recipient.name
                    return el
                })
            } catch (error) {
                console.error(error)
                throw error?.response?.data?.description || error.toString()
            }
        },
        async getPersons(filters) {
            try {
                const response = await this.$http.get(`/recipients/persons${makeUrlQuery(filters)}`, this.headerAuth)
                return response.data ? response.data.persons || [] : []
            } catch (error) {
                console.error(error)
                throw error?.response?.data?.description || error.toString()
            }
        },
        async postPerson(body) {
            try {
                const response = await this.$http.post(`/recipients/persons`, body, this.headerAuth)
                return response.data || {}
            } catch (error) {
                console.error(error)
                throw error?.response?.data?.description || error.toString()
            }
        },
        async putBranch(body) {
            try {
                const response = await this.$http.put(`/recipients/branches`, body, this.headerAuth)
                return response.data || {}
            } catch (error) {
                console.error(error)
                throw error?.response?.data?.description || error.toString()
            }
        },
        async putOrganization(body) {
            try {
                const response = await this.$http.put(`/recipients/organizations`, body, this.headerAuth)
                return response.data || {}
            } catch (error) {
                console.error(error)
                throw error?.response?.data?.description || error.toString()
            }
        },
        async getDefinitionIdByCrtId(crtKwalificatieId) {
            const definition = this.definitions.find(definition => definition?.properties["crt-id"] === crtKwalificatieId)

            if (definition) {
                return definition.id
            }

            try {
                const response = await this.$http.get(`/definitions?properties=crt-id:${crtKwalificatieId}`, this.headerAuth)
                const definition = response?.data?.definitions[0]

                if (!definition?.id) {
                    throw "Definition not found"
                }
                this.definitions.push(definition)
                return definition.id
            } catch (error) {
                console.error("Error in getDefinitionIdByCrtId:", error)
                throw error.toString()
            }
        },
        async getDefinitionIdByTitle(definitionTitle) {
            try {
                const response = await this.$http.get(`/definitions?title=${definitionTitle}`, this.headerAuth)
                const definition = response?.data?.definitions[0]

                if (!definition?.id) {
                    throw "Definition not found"
                }
                return definition.id
            } catch (error) {
                console.error("Error in getDefinitionIdByTitle:", error)
                throw error.toString()
            }
        },
        async getRegistrations(recipientId, filters) {
            try {
                const response = await this.$http.get(`/recipients/${this.recipientType}/${recipientId}/registrations${makeUrlQuery(filters)}`, this.headerAuth)
                return response?.data?.registrations || []
            } catch (error) {
                console.error("Error in getRegistration:", error)
                throw error?.response?.data?.description || error.toString()
            }
        },
        async postRegistration(recipientId, body) {
            try {
                const response = await this.$http.post(`/recipients/${this.recipientType}/${recipientId}/registrations`, body, this.headerAuth)
                return response.data || {}
            } catch (error) {
                console.error("Error in post registration", error)
                throw error?.response?.data?.description || error.toString()
            }
        },
        async patchRegistration(registrationId, body) {
            if (!registrationId) {
                console.error("patchRegistration: no registrationId")
                return {}
            }
            try {
                const response = await this.$http.patch(`/registrations/${registrationId}`, body, this.headerAuth)
                return response.data || {}
            } catch (error) {
                console.error("Error in patch registration mutation:", error)
                throw error?.response?.data?.description || error.toString()
            }
        },
        removeSuccesfulData() {
            if (window.confirm("Wil je de ge-uploade records verbergen?")) {
                this.tabledata = this.tabledata.filter(row => row.status !== "done")
            }
        },
        createRequestBody(data, settings, ignore = []) {
            return settings.reduce((accumulator, setting) => {
                if (ignore.indexOf(setting.field) >= 0) {
                    return accumulator
                }

                const requestField = setting.field === "referenceRecipient" || setting.field === "referenceRegistration" ? "reference" : setting.field

                if (setting.field === "definitionId") {
                    accumulator.definition = { id: data[setting.field] }
                } else if ((setting.type === "date" || setting.format === "email") && !data[setting.field]) {
                    accumulator[requestField] = null
                } else {
                    accumulator[requestField] = data[setting.field]
                }

                return accumulator
            }, {})
        },
    }
}

// useApi('/v1/recipients/{id}/', {
//     id: 4000049131-
//     body: {
//         person
//     }
// })

</script>
<style lang="scss">
.table-frame{
    flex: 1;
    overflow-x: auto;
    overflow-y: auto;
}
</style>