<template>
    <div class="page">

        <popup-template v-if="showPopup == 'template'" :template="template" @close="showPopup = ''"></popup-template>

        <h1>Sjablonen</h1>
        <section class="white">
            <div class="header-buttons">
                <button @click="addTemplate"><i v-html="$icon('plus')"></i>Sjabloon toevoegen</button>
            </div>
        </section>

        <section v-if="templates.length" class="white scroll">
            <rtable :columns="templateColumns" :data="templates" :clickable="true"></rtable>
        </section>

        <section v-else class="white scroll">
            <div class="no-templates-box">
                <i v-html="$icon('newspaper')" class="mid-page-icon"></i>
                <h3>Sjablonen maken</h3>
                <p>Met een Sjabloon kan een CSV geconverteerd worden in het juiste formaat.</p>
                <button @click="addTemplate"><i v-html="$icon('plus')" class="button-icon"></i>Sjabloon toevoegen</button>
            </div>
        </section>
    </div>
</template>

<script>

import popupTemplate from '@/components/popup-template.vue'
import rtable from '@/components/rtable.vue'

export default {
    name: 'templates',
    components: {
        popupTemplate,
        rtable
    },
    computed: {
        templates() {
            return this.$store.getters.templates
        },
    },
    data() {
        return {
            showPopup: "",
            template: {},
            templateColumns: [
                {title: "Naam", field: "name" },
                {title: "Ontvanger", field: "recipientType" },
                {title: "Bron", field: "resources" },
                {title: "Geblokkeerd", field: "locked", type: "boolean"}
            ]
        }
    },
    methods: {
        addTemplate() {
            this.template = {
                name: "",
                id: this.makeUuid(),
                recipientType: "",
                resources: [],
                settings: {}
            }
            this.showPopup = 'template'
        },
        makeUuid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0,
                    v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            })
        }
    }
}
</script>
<style lang="scss">
.no-templates-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // height: 70vh;
    padding: 20px 0;

    .mid-page-icon {
        color: var(--action-button);

        svg {
            width: 20vh;
        }
    }

    h3 {
        margin: 0 0 20px;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

    p {
        text-align: center;
        padding: 0px 70px;
        margin-bottom: 20px;
    }
}
</style>
