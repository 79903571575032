import axios from 'axios'

axios.defaults.baseURL =
	window.location.hostname == "upload.credentialregister.com" ? `https://api.wallet.credentialregister.com/v1` :
	window.location.hostname == "staging.upload.credentialregister.com" ? `https://staging.api.wallet.credentialregister.com/v1` :
	window.location.hostname == "test.upload.credentialregister.com" ? `https://test.api.wallet.credentialregister.com/v1` :
	`https://staging.api.wallet.credentialregister.com/v1`

axios.defaults.headers.common['Authorization'] = localStorage.getItem("token")
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.withCredentials = false

export default axios