<template>
    <div class="page">
        <popup-template v-if="showPopup == 'template'" :template="JSON.parse(JSON.stringify(template))" @close="showPopup = ''"></popup-template>

        <h1><router-link to="/templates"><i v-html="$icon('chevron-left')"></i>Sjablonen</router-link></h1>

        <section class="white">
            <div class="buttons">
                <button :disabled="template?.locked" class="" @click="showPopup = 'template'"><i v-html="$icon('pencil-square')"></i>Bewerken</button>
                <button :disabled="template?.locked" class="red" @click="deleteTemplate"><i v-html="$icon('trash')"></i>Verwijderen</button>
            </div>
        </section>

        <section class="white scroll">
            <h2 v-text="template.name"></h2>
            <ctable :data="template" :rows="[{field: 'recipientType', title: 'Ontvanger'}, {field: 'resources', title: 'Bronnen', type: 'labels'}]"></ctable>
            <h3>Mapping</h3>
            <ctable :data="template.settings" :rows="rows"></ctable>
        </section>
    </div>

</template>

<script>

import ctable from '@/components/ctable.vue'
import popupTemplate from '@/components/popup-template.vue'
import { getSettings } from "@/settings.js"

export default {
    name: 'templates',
    components: {
        ctable,
        popupTemplate
    },
    computed: {
        templates() {
            return this.$store.getters.templates
        },
        rows(){
            return this.settings.map(setting => {
                return {field: setting.field, title: setting.title, type: "text"}
            })
        },
        settings() {
            return getSettings(this.template?.resources || [])
        }
    },
    data() {
        return {
            showPopup: "",
            template: {}
        };
    },
    mounted(){
        this.setTemplate()
    },
    watch:{
        templates(){
            this.setTemplate()
        }
    },
    methods: {
        setTemplate(){
            const template = this.templates.find(template => template.id == this.$route.params.id) || {}
            this.template = {...template}
        },
        deleteTemplate() {
            if (window.confirm("Wil je dit Sjabloon wissen?")) {

                let userSettings = this.$store.getters.settings
                userSettings.templates = userSettings.templates || []
                userSettings.templates = userSettings.templates.filter(template => template.id !== this.template.id)

                this.$store.dispatch("patchMeSettings", userSettings).then(() => {
                    this.$router.push("/templates")
                })
            }
        }
    }
}
</script>
<style lang="scss">
</style>
