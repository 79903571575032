export default [
    {
        "field": "cocNumber",
        "title": "KvK nummer",
        "description": "The Chamber of Commerce number of the company",
        "pattern": /^[0-9]{8}$/,
        "type": "string",
        "required": true,
    },
    {
        "field": "tradeName",
        "title": "Handelsnaam",
        "description": "The trade name of the branch",
        "pattern": /^[\S ]{0,255}$/,
        "type": "string",
        "required": false,
    },
    // {
    //     "field": "referenceRecipient",
    //     "title": "Refgerentie",
    //     "description": "The reference of the branch",
    //     "pattern": /^[\u0020-\u007E]{1,100}$/,
    //     "type": "string",
    //     "required": false,
    // },
    {
        "field": "deregistrationDate",
        "title": "Datum Uitgeschreven",
        "description": "Datum Uitgeschreven",
        "type": "date",
        "required": false,
    },
    {
        "field": "phone",
        "title": "Telefoon",
        "description": "The phone number of the branch",
        "type": "string",
        "required": false,
    },
    {
        "field": "email",
        "title": "Email",
        "description": "The email address of the branch",
        "pattern": /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "type": "string",
        "format": "email",
        "required": false,
    }
]
