import { createStore } from 'vuex'
import axios from './axios.js'
import defaultTemplates from '@/templates/default-templates.js'

export default createStore({
    state: {
        email: "",
        me: {},
        token: localStorage.getItem("token") || "",
        activeMe: {},
        activeToken: "",
        grants: [],
    },
    mutations: {
        email(state, email) {
            state.email = email || ""
        },
        me(state, me) {
            state.me = me || {}
        },
        activeMe(state, me) {
            state.activeMe = me || {}
        },
        activeToken(state, token) {
            state.activeToken = token || {}
        },
        grants(state, grants) {
            state.grants = grants || []
        },
        setToken(state, token) {
            state.token = token
            state.activeToken = token
            localStorage.setItem("token", token)
            localStorage.setItem("activeToken", token)
        },
        logout(state) {
            state.email = ""
            state.me = {}
            state.token = ""
            state.activeMe = {}
            state.activeToken = ""
            state.grants = []
            localStorage.removeItem("token")
            localStorage.removeItem("activeToken")
        },
    },
    actions: {
        register({ commit }, email) {
            return new Promise((resolve, reject) => {
                axios.post("/register", { email }).then(response => {
                    commit("email", email)
                    resolve()
                }).catch(err => {
                    reject(err)
                })
            })
        },
        login({ commit }, email) {
            return new Promise((resolve, reject) => {
                axios.post("/login", { email }).then(response => {
                    commit("email", email)
                    resolve()
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getToken({ commit, state, dispatch }, otp) {
            return new Promise((resolve, reject) => {
                axios.post("/token", { email: state.email, otp, client: "desktop"}).then(response => {
                    commit("setToken", response.data.token || "")
                    dispatch("getMe").then(() => {
                        resolve()
                    }).catch(err => {
                        reject(err)
                    })
                }).catch(err => {
                    reject(err)
                })
            })
        },
        logout({ commit, state }) {
            return new Promise((resolve, reject) => {
                if (state.token) {
                    axios({ method: 'post', url: '/logout', headers: { Authorization: state.token } }).then(response => {
                        commit("logout")
                        resolve()
                    }).catch(err => {
                        reject(err)
                    })
                } else {
                    commit("logout")
                    reject("no token")
                }
            })
        },
        getMe({ state, commit, dispatch }) {
            return new Promise((resolve, reject) => {
                const token = state.activeToken || state.token
                if (token) {
                    axios.get("/me", { headers: { Authorization: token } }).then(response => {
                        if(state.token == token){
                            commit("me", response.data)
                            dispatch("getGrants").then(() => {
                                resolve()
                            }).catch(err => {
                                reject(err)
                            })
                        }
                        commit("activeToken", token)
                        commit("activeMe", response.data)
                        // dispatch('mergeDefaultTemplates')
                        resolve()
                    }).catch(err => {
                        commit("logout")
                        reject(err)
                    })
                } else {
                    commit("logout")
                    reject("no token")
                }
            })
        },
        patchMeSettings({ commit, state }, settings) {
            return new Promise((resolve, reject) => {
                axios.patch("/me", { settings }, { headers: { Authorization: state.activeToken } }).then(response => {
                    if (response.status === 200) {
                        commit('activeMe', response.data)
                    }
                    resolve()
                }).catch(err => {
                    console.error(err)
                    reject(err)
                })
            })
        },
        getGrants({ state, commit }) {
            return new Promise((resolve, reject) => {
                axios.get("/grants", { headers: { Authorization: state.token } }).then(response => {
                    if (response.status === 200) {
                        commit('grants', response.data.grants || [])
                    }
                    resolve()
                }).catch(err => {
                    console.error(err)
                    reject(err)
                })
            })
        },
    },
    getters: {
        email: (state) => {
            return state.email || ""
        },
        token: (state) => {
            return state.token || localStorage.getItem("token") || ""
        },
        activeToken: (state) => {
            return state.activeToken || state.token || ""
        },
        headerAuth: (state) => {
            const token = state.activeToken || state.token || ""
            return token ? { headers: { Authorization: token } } : null
        },
        me: (state) => {
            return state.me || {}
        },
        activeMe: (state) => {
            return state.activeMe || {}
        },
        settings: (state) => {
            return state.activeMe.settings || {}
        },
        userTemplates: (state) => {
            return state?.activeMe?.settings?.templates || []
        },
        templates(state){
            return (state?.activeMe?.settings?.templates || []).concat(defaultTemplates.map(template => {
                template.locked = true
                return template
            }))
        },
        grants: (state) => {
            return state.grants || []
        },
    },
    modules: {
    }
})
