export default [
    {
        "field": "definitionId",
        "title": "Definitie ID",
        "description": "The definition ID",
        "type": "string",
        "pattern": /[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}/,
        "required": false,
    },
    {
        "field": "definitionTitle",
        "title": "Definitie Titel",
        "description": "Definition Title",
        "type": "string",
        "required": false,
    },
    {
        "field": "definitionCrtId",
        "title": "CRT Kwalificatie ID",
        "description": "CRT Qualification ID",
        "type": "string",
        "required": false,
    },
    {
        "field": "startDate",
        "title": "Startdatum",
        "description": "The start date of the person",
        "type": "date",
        "required": true,
    },
    {
        "field": "endDate",
        "title": "Einddatum",
        "description": "The end date of the person",
        "type": "date",
        "required": false,
    },
    {
        "field": "evaluationDate",
        "title": "Evaluatiedatum",
        "description": "",
        "type": "date",
        "required": false,
    },
    {
        "field": "revocationDate",
        "title": "Datum Ingetrokken",
        "description": "",
        "type": "date",
        "required": false,
    },
    {
        "field": "number",
        "title": "Registratie Nummer",
        "description": "Registration Number",
        "type": "string",
        "required": false,
    },
    {
        "field": "referenceRegistration",
        "title": "Registratie Referentie",
        "description": "Registration Reference",
        "type": "string",
        "required": false,
    }
]