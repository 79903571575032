import { createRouter, createWebHistory } from 'vue-router'

import Home from './views/home.vue'
import Recipients from './views/recipients.vue'
import Recipient from './views/recipient.vue'
import Login from './views/sessions/login.vue'
import Register from './views/sessions/register.vue'
import OTP from './views/sessions/otp.vue'
import Templates from './views/templates.vue'
import Template from './views/template.vue'

const routes = [{
    path: '/',
    name: 'home',
    component: Home
},{
    path: '/persons',
    name: 'persons',
    meta: {recipientType: "persons", title: "Personen"},
    component: Recipients
},{
    path: '/persons/:id',
    name: 'person',
    meta: {recipientType: "persons", title: "Personen"},
    component: Recipient
},{
    path: '/branches',
    name: 'branches',
    meta: {recipientType: "branches", title: "Vestigingen"},
    component: Recipients
},{
    path: '/branches/:id',
    name: 'branch',
    meta: {recipientType: "branches", title: "Vestigingen"},
    component: Recipient
},{
    path: '/organizations',
    name: 'organizations',
    meta: {recipientType: "organizations", title: "Organisaties"},
    component: Recipients
},{
    path: '/organizations/:id',
    name: 'organization',
    meta: {recipientType: "organizations", title: "Organisaties"},
    component: Recipient
},{
    path: '/templates',
    name: 'templates',
    component: Templates
},{
    path: '/templates/:id',
    name: 'template',
    component: Template
},{
    path: '/login',
    name: 'login',
    component: Login
},{
    path: '/register',
    name: 'register',
    component: Register
},{
    path: '/otp',
    name: 'otp',
    component: OTP
}]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router