<template>
    <popup @close="close" :width="600" :error="error">
        <template v-slot:header>
            Sjabloon
        </template>
        <template v-slot:content>

            <div :class="['field', {'error': errors.name}]">
                <label>Naam</label>
                <input v-model="template.name" xvalue="template.name" type="text" placeholder="Sjabloon naam" xclass="{ incorrect: !template.settings[setting.name]?.source && emptyFields }" :disabled="template.locked" />
                <p class="error">Verplicht veld</p>
            </div>

            <div :class="['field', 'select', {'error': errors.recipientType}]">
                <label>Ontvanger</label>
                <div class="selectwrapper">
                    <select v-model="template.recipientType" :disabled="template.locked">
                        <option value="">Type ontvanger</option>
                        <option value="persons">Persoon</option>
                        <option value="branches">Vestiging</option>
                        <option value="organizations">Organisatie</option>
                    </select>
                </div>
                <p class="error">Verplicht veld</p>
            </div>

            <h4>Mapping</h4>

            <p class="info" v-if="!settings.length">Selecteer eerst een Ontvanger</p>

            <div :class="['field', {'error': errors[setting.field]}]" v-for="(setting, idx) in settings" :key="idx">
                <label v-text="setting.title"></label>
                <input v-model="template.settings[setting.field]" class="code" xvalue="setting.source" type="text" :placeholder="setting.title" xclass="{ incorrect: !template.settings[setting.name]?.source && emptyFields }" :disabled="template.locked" />
                <p class="error">Verplicht veld</p>
            </div>

        </template>

        <template v-slot:footer>
            <button @click="close" class="light"><i v-html="$icon('no-symbol')"></i>Cancel</button>
            <button @click="save"><i v-html="$icon('cloud-arrow-up')"></i>Save</button>
        </template>

     </popup>
</template>

<script>

import popup from '@/components/popup.vue'
import { getSettings } from "@/settings.js"

export default {
    name: 'popup-template',
    props: {
        template: {
            type: Object,
            default: () => { return {} }
        }
    },
    components: {
        popup
    },
    computed: {
        settings() {
            return getSettings(this.template?.resources || [])
        }
    },
    watch: {
        "template.recipientType"() {
            if(this.template.recipientType == "persons"){
                this.template.resources = ["persons", "registrations"]
            }
            else if(this.template.recipientType == "branches"){
                this.template.resources = ["branches", "registrations"]
            }
            else if(this.template.recipientType == "organizations"){
                this.template.resources = ["organizations", "registrations"]
            }
            else {
                this.template.resources = []
            }
        }
    },
    data() {
        return {
            errors: {},
            error: false
        }
    },
    methods: {
        save(){
            this.error = this.checkForm()

            if(!this.error){
                let userSettings = this.$store.getters.settings
                userSettings.templates = userSettings.templates || []

                const idx = userSettings.templates.findIndex(el => el.id == this.template.id)

                if(idx >= 0){
                    userSettings.templates[idx] = this.template
                }else{
                    userSettings.templates.push(this.template)
                }

                this.$store.dispatch("patchMeSettings", userSettings).then(() => {
                    this.$emit("close")
                })
            }
        },
        close(){
            this.$emit("close")
        },
        checkForm(){
            this.errors = this.settings.reduce((acc, setting) => {
                acc[setting.field] = setting.required && !this.template.settings[setting.field] ? true : false
                return acc
            }, {})

            this.errors.name = !this.template.name
            this.errors.recipientType = !this.template.recipientType

            return Object.keys(this.errors).reduce((acc, key) => {
                return acc || this.errors[key]
            }, false)
        }
    }
}
</script>
<style lang="scss">

</style>