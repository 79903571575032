<template>
    <!-- <transition name="popup-transition" v-if="show"> -->
        <div class="popup-overlay" click.stop="close">
            <div class="popup-frame" :style="style" :class="[{'error': error}]">
                <div class="popup-header">
                    <span class="close-button" @click="close"><i v-html="$icon('x-mark')"></i></span>
                    <h3><slot name="header">Title</slot></h3>
                </div>
                <div class="popup-content">
                    <slot name="content">Content</slot>
                </div>
                <div class="popup-footer">
                    <slot name="footer">Footer</slot>
                </div>
            </div>
        </div>
    <!-- </transition> -->
</template>

<script>
export default {
    name: 'popup',
    props: {
        width: {
            type: Number,
            default: 0
        },
        error: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        style(){
            return this.width ? {"width": this.width + "px"} : {}
        }
    },
    methods: {
        close() {
            this.$emit("close")
        }
    }
};
</script>

<style lang="scss">
// .popup-transition-enter-active,
// .popup-transition-leave-active {
//     transition: opacity 1s, transform 1s;
// }

// .popup-transition-enter-from,
// .popup-transition-leave-to {
//     transition: opacity 1s, transform 1s;
//     // opacity: 0;
// }

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(99, 122, 153, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .popup-frame {
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: var(--bg); //#FAF7FC;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        border: 2px solid transparent;
        border-radius: 26px;

        max-width: 95%;
        max-height: 95%;

        &.error {
            border-color: #BE0037;
        }

        .popup-header {
            text-align: center;
            padding: 20px 40px 10px 40px;

            h3 {
                font-size: 20px;
                line-height: 20px;
                font-weight: 600;
                margin: 0;
                padding: 0;
            }

            .close-button {
                font-size: 20px;
                cursor: pointer;
                color: black;
                position: absolute;
                top: 15px;
                right: 20px;
                position: absolute;
            }
        }

        .popup-content {
            padding: 10px 40px 10px 40px;
            //overflow-y: auto;
            overflow: hidden;
            height: 100vh;
            display: flex;
            flex-direction: column;

            h4 {
                font-size: 18px;
                line-height: 18px;
                font-weight: 600;
                margin: 30px 0 20px 0;
                padding: 0;
            }
        }

        .popup-footer {
            padding: 10px 40px 20px 40px;
            display: flex;
            justify-content: center; //space-between;
            column-gap: 10px;

            button {
                // width: 40%;
            }
        }
    }
}
</style>