<template>
    <div class="switchgrants">
        <div v-if="accountPopupActive" class="popup-overlay" @click="displayAccountPopup()"></div>

        <div class="switchgrants-content" @click="displayAccountPopup()">
            <div class="avatar">
                <img v-if="activeMe.image" :src="activeMe.image" alt="avatar" @error="imageUrlAlt">
                <img v-else src="/avatar.png" alt="avatar">
            </div>
            <div class="accountname">{{activeMe.name}}</div>
            <p v-if="activeToken !== token" class="granted-text">Gedelegeerd ({{ me.name }})</p>
            <i v-html="$icon('arrows-right-left')"></i>
        </div>
        <transition name="popup">
            <div v-if="accountPopupActive" class="account-popup" ref="accountPopup">
                <div class="account-popup-item">
                    <div :class="[{'account-popup-item-content-active': token === activeToken}, 'account-popup-item-content']" @click="chooseAccount(token)">
                        <div class="avatar">
                            <img v-if="me.image" :src="me.image" alt="avatar" @error="imageUrlAlt">
                            <img v-else src="/avatar.png" alt="avatar" >
                        </div>
                        <span class="popup-account-name">{{ me.name ? me.name : me.email }}</span>
                        <i v-if="token === activeToken" v-html="$icon('check')"></i>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="account-popup-item">
                    <h3>Gedelegeerd accounts</h3>
                    <div v-for="grant in grants" :class="[{'account-popup-item-content-active': grant.token === activeToken}, 'account-popup-item-content']" @click="chooseAccount(grant.token)">
                        <div class="avatar">
                            <img v-if="grant.account.image" :src="grant.account.image" alt="avatar" @error="imageUrlAlt">
                            <img v-else src="/avatar.png" alt="avatar">
                        </div>
                        <span class="popup-account-name">{{ grant.account.name ? grant.account.name : grant.account.email }}</span>
                        <i v-if="grant.token === activeToken" v-html="$icon('check')"></i>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="sign-out-button">
                    <button @click="signOut">
                        <span>Uitloggen</span>
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    name: 'switcher',
    props: {
        me: {
            type: Object,
            default: () => { return {} }
        },
        grants: {
            type: Array,
            default: () => { return [] }
        },
        activeMe: {
            type: Object,
            default: () => { return {} }
        },
        token: {
            type: String,
            default: ''
        },
        activeToken: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            accountPopupActive: false,
        }
    },
    methods: {
        imageUrlAlt(e) {
            e.target.src = '/avatar.png'
        },
        displayAccountPopup() {
            this.accountPopupActive = !this.accountPopupActive
        },
        chooseAccount(token) {
            this.$emit('chooseAccount', token)
            this.accountPopupActive = false
        },
        signOut() {
            this.$emit('signOut')
            this.accountPopupActive = false
        }
    }
}
</script>
<style lang="scss">
.switchgrants {
    justify-content: flex-end;
    right: 0px;
    padding: 5px;
    border-radius: 12px;
    background-color: #FAF7FC;
    position: absolute;
    width: 220px;
    border: 2px solid transparent;

    .switchgrants-content {
        display: flex;
        align-items: center;
        padding: 0 10px;
        cursor: pointer;

        .accountname {
            color: #606673;
            font-size: 0.9em;
            font-weight: 700;
            cursor: pointer;
            margin-right: 10px;
            line-height: 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 150px;
        }

        p {
            padding : 0;
            color: #1C53D7;
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;

            &.granted-text {
                position: absolute;
                bottom: 2px;
                left: 60px;
            }
        }

        i {
            width: 20px;
            height: 20px;
            float: right;
            text-align: center;
            margin-left: auto;
            cursor: pointer;
            color: var(--menutext);
        }
    }

    &:hover {
        background-color: var(--action-button);
        border: 2px solid var(--border);

        .switchgrants-content {
            color: var(--white);

            .avatar {
                border: 1px solid var(--white);
            }

            i {
                color: var(--border);
            }
        }
    }

    .account-popup {
        position: absolute;
        top: 60px;
        right: 15px;
        width: 250px;
        background-color: #FAF7FC;
        border-radius: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        padding: 10px 12px;
        border: var(--border);
        display: flex;
        flex-direction: column;
        transition: all 2s ease-in-out;

        .account-popup-item {
            list-style: none;
            margin: 0;


            h3 {
                font-family: 'Open Sans', sans-serif;
                text-wrap: wrap;
                color: #262E40;
                font-size: 0.9em;
                font-weight: 700;
                text-wrap: wrap;
            }


            .account-popup-item-content {
                display: flex;
                border-radius: 13px;
                flex-direction: row;
                padding: 5px 10px;
                align-items: center;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                background-color: var(--bg);


                .popup-account-name {
                    color: #606673;
                    font-size: 0.9em;
                    font-weight: 700;
                    cursor: pointer;
                    margin-right: 10px;
                    line-height: 15px;
                    word-wrap: break-word;
                    max-width: 150px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
                

                &:hover {
                    background-color: var(--bg);
                }

                h3{
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 0.9em;
                    font-weight: 700;
                }

                i {
                    width: 20px;
                    height: 20px;
                    margin-left: auto;
                    flex: 0 2 auto;
                }
            }

            .account-popup-item-content-active {
                background-color: var(--action-button);
                color: var(--white);
                border-radius: 13px;

                i {
                    svg {
                        stroke: var(--blue);
                        stroke-width: 2;
                    }
                }
            }

        }

        .account-popup-item-content+.account-popup-item-content {
            margin-top: 5px;
        }

        .divider {
            margin-top: 10px;
            border-top: 1px solid #eee;
        }

        .sign-out-button{
            margin-top: 10px;
            button{
                width: 100%;
                height: 40px;
                background-color: transparent;
                border: none;
                outline: none;
                cursor: pointer;
                color: #BE0037;
                font-size: 0.9em;
                font-weight: 700;
                display: flex;
            }

            button:hover{
                background-color: var(--bg);
                border-radius: 10px;
                
            }
        }


    }

    .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        z-index: 999;
    }

    .popup-enter-active,
    .popup-leave-active {
        transition: all 0.5s ease-out;
    }

    .popup-enter-from,
    .popup-leave-to {
        transform: translateY(-10px);
        opacity: 0;
    }

    .avatar {
        width: 100%;
        height: 100%;
        max-height: 35px;
        max-width: 35px;
        border-radius: 25%;
        overflow: hidden;
        margin-right: 10px;
        flex: 0 1 auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
</style>